import React from "react";
import styles from "@aspen/theme/Event.module.less";
import variables from "@aspen/theme/variables.module.less";
import { dateFormat, i18nUtil, toLocaleTime } from "@aspen/libs";
import { ConferenceVO, IEventStatus } from "@aspen/model";
import Image from "next/image";
import dayjs from "dayjs";
import cash from "@aspen/assets/images/event/event_ic_cash.webp"
import hum from "@aspen/assets/images/event/event_ic_hum.webp"
import link from "@aspen/assets/images/event/event_ic_link.webp"
import pos from "@aspen/assets/images/event/event_ic_pos.webp"
import time from "@aspen/assets/images/event/event_ic_time.webp"
import { Button } from "antd";
import { EventTag } from "./EventTag";

/**
 * 移动端使用关注data，status，isMobile即可
 */
interface IProps {
  data: ConferenceVO | undefined;
  status: IEventStatus | undefined;
  register?: () => void;
  cancel?: () => void;
  isMobile?: boolean
  bookStatus?: boolean
  loading?: boolean
}

const EventRegisterInfoView: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const {data, isMobile = false, status, register, cancel, bookStatus = false, loading = false} = props;
  const isSkipDay = toLocaleTime(data?.startTime || "", dateFormat) != toLocaleTime(data?.endTime || "", dateFormat);
  const location = data?.type == "OFFLINE" ? data?.address || data?.location : data?.location;
  const list: Array<{
    title: string;
    value: string | number | undefined;
    icon: any;
    downloadUrl?: string;
  }> = [
    {
      title: `${intl["event.detail.time"]}:`,
      value: `${toLocaleTime(data?.startTime || "", "YYYY.MM.DD HH:mm")} - ${toLocaleTime(
        data?.endTime || "",
        isSkipDay ? "MM.DD HH:mm" : "HH:mm",
      )} (UTC${dayjs().format("Z")})`,
      icon: time,
    },
    {
      title: `${intl["event.detail.host"]}:`,
      value: data?.host,
      icon: hum,
    },
    {
      title: `${intl["event.detail.price"]}:`,
      value: data?.price,
      icon: cash,
    },
    {
      title: `${intl["event.detail.location"]}:`,
      value: location,
      icon: pos,
    },
    {
      title: `${intl["event.detail.attachment"]}:`,
      value: data?.attachmentName && data?.attachmentLink ? data?.attachmentName : undefined,
      icon: link,
      downloadUrl: data?.attachmentLink,
    },
  ];
  const _renderItem = (list) => {
    return list.map((item, index) => {
      return <div key={`registerInfoItem-${index}`} className={styles.registerInfoItem}>
        <Image unoptimized src={item?.icon} alt="" width={18} height={18}/>
        <span className={styles.boldTxt}>{item.title}</span>
        {item?.value ? <span className={index == list.length - 1 ? styles.endValue : styles.value}
                             onClick={() => {
                               item?.downloadUrl && window.open(item.downloadUrl);
                             }}
                             style={item?.downloadUrl ? {
                               color: variables.mainColor,
                               textDecoration: "underline",
                               cursor: 'pointer'
                             } : {}}>{item.value}</span> : <span className={styles.boldTxt}>--</span>}
      </div>
    })
  }
  const _renderBtn = () => {
    if (isMobile || (status !== "coming" || !data)) {
      return
    }

    if ((data?.type === "OFFLINE" && !bookStatus) || (data?.type === "ONLINE" && data?.address)) {
      return (
        <Button
          loading={loading}
          className={styles.btn}
          type="primary"
          onClick={() => {
            register && register()
          }}>
          {intl["event.detail.Register"]}
        </Button>
      )
    }

    if (bookStatus) {
      return <Button
        loading={loading}
        className={styles.btn}
        type="primary"
        onClick={() => {
          cancel && cancel()
        }}>
        {intl["event.detail.cancel"]}
      </Button>
    }

  }
  return (
    <div className={styles.registerInfo}>
      {isMobile ? <EventTag status={status} isMobile={isMobile} style={{marginBottom: 16}}/> : null}
      {_renderItem(list)}
      {_renderBtn()}
    </div>
  );
};
export const EventRegisterInfo = React.memo(EventRegisterInfoView);
