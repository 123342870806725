import cookie from "js-cookie";
import { RESERVED_KEYS } from "../config";

const cookieUtil = {
  set: (name: string, value: string) => {
    cookie.set(name, value ?? "");
  },
  remove: (name: string) => {
    cookie.remove(name);
  },
  // 获取所有的cookie
  get: () => {
    return cookie.get();
  }
};

export { cookieUtil };

/**
 * 循环清除其它所有locastorage，除过RESERVED_KEYS信息
 */
export const clearLocalStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    // 缓存数据统一约定key里加关键字符串 _cache_
    if (!RESERVED_KEYS.includes(key) && !key.includes("_cache_")) {
      localStorage.removeItem(key);
    }
  });
};

/**
 * 循环清除所有cookie
 */
export const clearCookies = () => {
  const allCookies = cookieUtil.get();
  Object.keys(allCookies).forEach((key) => {
    cookieUtil.remove(key);
  });
};
