import dynamic from "next/dynamic";
import InnerVentureSell from "./ventureSell/InnerVentureSell"
import OtherVentureSell from "./ventureSell/OtherVentureSell"

export const ModalPrimarySubscribe = dynamic(() => import("./ModalPrimarySubscribe"), {
  ssr: false
});

export const ModalSecondarySubscribe = dynamic(() => import("./ModalSecondarySubscribe"), {
  ssr: false
});

export const ModalShares = dynamic(() => import("./ModalShares"), {
  ssr: false
});

export const ModalSharesSuccResult = dynamic(() => import("./ModalSharesSuccResult"), {
  ssr: false
});

export const ModalSubscribeSuccResult = dynamic(() => import("./ModalSubscribeSuccResult"), {
  ssr: false
});

export const SellConfirmInfo = dynamic(() => import("./ventureSell/SellConfirmInfo"), {
  ssr: false
});

export const ModalVentureSell = dynamic(() => import("./ModalVentureSell"), {
  ssr: false
});

export * from "./Primary";

export * from "./Secondary";

export * from "./VentureSubscribeOrSellType";

export  {
  InnerVentureSell,
  OtherVentureSell,
}
