import React from "react";
import styles from "@aspen/theme/Event.module.less";
import { dateFormat, i18nUtil, toLocaleTime } from "@aspen/libs";
import { ConferenceVO, IEventStatus } from "@aspen/model";
import Image from "next/image";
import dayjs from "dayjs";
import cash from "@aspen/assets/images/event/event_ic_cash.webp";
import hum from "@aspen/assets/images/event/event_ic_hum.webp";
import link from "@aspen/assets/images/event/event_ic_link.webp";
import pos from "@aspen/assets/images/event/event_ic_pos.webp";
import time from "@aspen/assets/images/event/event_ic_time.webp";
import { EventTag } from "./EventTag";
import variables from "@aspen/theme/variables.module.less";

/**
 * 移动端使用关注data，status，isMobile即可
 */
interface IProps {
  data: ConferenceVO;
  status: IEventStatus;
}

const EventRegisterInfoView: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const { data, status } = props;
  const isSkipDay =
    toLocaleTime(data?.startTime || "", dateFormat) !=
    toLocaleTime(data?.endTime || "", dateFormat);
  const location = data?.type == "OFFLINE" ? data?.address || data?.location : data?.location;
  const list: Array<{
    title: string;
    value: string | number | undefined;
    icon: any;
    downloadUrl?: string;
  }> = [
    {
      title: `${intl["event.detail.time"]}:`,
      value: `${toLocaleTime(data?.startTime || "", "YYYY.MM.DD HH:mm")} - ${toLocaleTime(
        data?.endTime || "",
        isSkipDay ? "MM.DD HH:mm" : "HH:mm"
      )} (UTC${dayjs().format("Z")})`,
      icon: time
    },
    {
      title: `${intl["event.detail.host"]}:`,
      value: data?.host,
      icon: hum
    },
    {
      title: `${intl["event.detail.price"]}:`,
      value: data?.price,
      icon: cash
    },
    {
      title: `${intl["event.detail.location"]}:`,
      value: location,
      icon: pos
    },
    {
      title: `${intl["event.detail.attachment"]}:`,
      value: data?.attachmentName && data?.attachmentLink ? data?.attachmentName : undefined,
      icon: link,
      downloadUrl: data?.attachmentLink
    }
  ];
  const _renderItem = (list) => {
    return list.map((item, index) => {
      if (!item.value) {
        return null;
      }

      return (
        <div key={`registerInfoItem-${index}`} className={styles.registerInfoItem}>
          <Image unoptimized src={item?.icon} alt="" width={12} height={12} />
          <span className={styles.itemTitle} style={{ color: variables.opWhite7 }}>
            {item.title}
          </span>
          <span
            className={styles.itemValue}
            onClick={() => {
              item?.downloadUrl && window.open(item.downloadUrl);
            }}
            style={
              item?.downloadUrl
                ? {
                    color: "#F17E00",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }
                : {}
            }>
            {item.value}
          </span>
        </div>
      );
    });
  };

  return (
    <div className={styles.appRegisterInfo}>
      <div className={styles.title}>{data?.title}</div>
      <EventTag status={status} isMobile={true} style={{ marginTop: 16 }} />
      {_renderItem(list)}
    </div>
  );
};
export const AppEventRegisterInfo = React.memo(EventRegisterInfoView);
