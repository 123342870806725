export const localforageStorage = {
  setItem: (name: string, value: string) => {
    try {
      localStorage.setItem(name, value);
    } catch (error) {
      console.log("Failed to execute 'setItem' on 'Storage'");
    }
  },
  getItem: (name: string) => {
    try {
      const data = localStorage.getItem(name);
      return data ? data : "";
    } catch (error) {
      console.log("Failed to execute 'getItem' on 'Storage'");
    }
  },
};
