import { useSWRData } from "../useSWRData";
import { EN, LANGUAGE, generateDataCacheKey, isClient } from "@aspen/libs/index";
import { ICommonCacheKeys, ILanguageType } from "@aspen/model/index";
import { useFecthers } from "../useFecthers/index";
import { DATA_CACHES_KEYS } from "@aspen/services/index";

/**
 * @description 可复用的请求数据，接口地址+参数一致即可复用
 * 只需要指定组件使用什么数据，不需要关心数据的获取和更新，同时可以拿到接口返回数据在自己业务逻辑中使用
 * @param key 唯一key名称
 */

function useCachePriorityData<T>(key: ICommonCacheKeys, params?: any) {
  if (!key) return;

  const _language: ILanguageType = isClient
    ? (window.localStorage.getItem(LANGUAGE) as ILanguageType) ?? EN
    : EN;

  let res;
  const param = params ? params : DATA_CACHES_KEYS[key]?.param;
  const cacheKey = generateDataCacheKey({
    params: JSON.stringify(param),
    api: DATA_CACHES_KEYS[key].api,
    language: _language
  });
  let fetcher = useFecthers(key, params, cacheKey);
  let extraSWRoptions = {};
  extraSWRoptions = {
    ...DATA_CACHES_KEYS[key]?.options
  };
  res = useSWRData<T>({
    key: cacheKey,
    fetcher: fetcher,
    options: {
      ...extraSWRoptions
    }
  });

  const { data, error, isLoading, isValidating, mutate } = res;

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate
  };
}

export { useCachePriorityData };
