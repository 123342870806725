export const CountriesForPhone = [
  {
    telephoneCode: "+61",
    displayName: "Australia",
    commonName: "澳大利亚",
    countryCode: "AUS",
    id: "AU",
    smsLanguage: "英文",
    nationalFlag: "🇦🇺"
  },
  {
    telephoneCode: "+43",
    displayName: "Austria",
    commonName: "奥地利",
    countryCode: "AUT",
    id: "AT",
    smsLanguage: "英文",
    nationalFlag: "🇦🇹"
  },
  // {
  //   telephoneCode: "+86",
  //   displayName: "China",
  //   commonName: "中国",
  //   countryCode: "CHN",
  //   id: "CN",
  //   smsLanguage: "简体中文",
  //   nationalFlag: "🇨🇳"
  // },
  {
    telephoneCode: "+45",
    displayName: "Denmark",
    commonName: "丹麦",
    countryCode: "DNK",
    id: "DK",
    smsLanguage: "英文",
    nationalFlag: "🇩🇰"
  },
  {
    telephoneCode: "+20",
    displayName: "Egypt",
    commonName: "埃及",
    countryCode: "EGY",
    id: "EG",
    smsLanguage: "英文",
    nationalFlag: "🇪🇬"
  },
  {
    telephoneCode: "+358",
    displayName: "Finland",
    commonName: "芬兰",
    countryCode: "FIN",
    id: "FI",
    smsLanguage: "英文",
    nationalFlag: "🇫🇮"
  },
  {
    telephoneCode: "+33",
    displayName: "France",
    commonName: "法国",
    countryCode: "FRA",
    id: "FR",
    smsLanguage: "英文",
    nationalFlag: "🇫🇷"
  },
  {
    telephoneCode: "+49",
    displayName: "Germany",
    commonName: "德国",
    countryCode: "DEU",
    id: "DE",
    smsLanguage: "英文",
    nationalFlag: "🇩🇪"
  },
  {
    telephoneCode: "+30",
    displayName: "Greece",
    commonName: "希腊",
    countryCode: "GRC",
    id: "GR",
    smsLanguage: "英文",
    nationalFlag: "🇬🇷"
  },
  {
    telephoneCode: "+852",
    displayName: "Hong Kong",
    commonName: "香港",
    countryCode: "HKG",
    id: "HK",
    smsLanguage: "英文",
    nationalFlag: "🇭🇰"
  },
  {
    telephoneCode: "+62",
    displayName: "Indonesia",
    commonName: "印度尼西亚",
    countryCode: "IDN",
    id: "ID",
    smsLanguage: "英文",
    nationalFlag: "🇮🇩"
  },
  {
    telephoneCode: "+353",
    displayName: "Ireland",
    commonName: "爱尔兰",
    countryCode: "IRL",
    id: "IE",
    smsLanguage: "英文",
    nationalFlag: "🇮🇪"
  },
  {
    telephoneCode: "+972",
    displayName: "Israel",
    commonName: "以色列",
    countryCode: "ISR",
    id: "IL",
    smsLanguage: "英文",
    nationalFlag: "🇮🇱"
  },
  {
    telephoneCode: "+39",
    displayName: "Italy",
    commonName: "意大利",
    countryCode: "ITA",
    id: "IT",
    smsLanguage: "英文",
    nationalFlag: "🇮🇹"
  },
  {
    telephoneCode: "+81",
    displayName: "Japan",
    countryCode: "JPN",
    commonName: "日本",
    id: "JP",
    smsLanguage: "英文",
    nationalFlag: "🇯🇵"
  },
  {
    telephoneCode: "+853",
    displayName: "Macau",
    commonName: "澳门",
    countryCode: "MAC",
    id: "MO",
    smsLanguage: "英文",
    nationalFlag: "🇲🇴"
  },
  {
    telephoneCode: "+60",
    displayName: "Malaysia",
    commonName: "马来西亚",
    countryCode: "MYS",
    id: "MY",
    smsLanguage: "英文",
    nationalFlag: "🇲🇾"
  },
  {
    telephoneCode: "+377",
    displayName: "Monaco",
    commonName: "摩纳哥",
    countryCode: "MCO",
    id: "MC",
    smsLanguage: "英文",
    nationalFlag: "🇲🇨"
  },
  {
    telephoneCode: "+64",
    displayName: "New Zealand",
    commonName: "新西兰",
    countryCode: "NZL",
    id: "NZ",
    smsLanguage: "英文",
    nationalFlag: "🇳🇿"
  },
  {
    telephoneCode: "+47",
    displayName: "Norway",
    commonName: "挪威",
    countryCode: "NOR",
    id: "NO",
    smsLanguage: "英文",
    nationalFlag: "🇳🇴"
  },
  {
    telephoneCode: "+63",
    displayName: "Philippines",
    commonName: "菲律宾",
    countryCode: "PHL",
    id: "PH",
    smsLanguage: "英文",
    nationalFlag: "🇵🇭"
  },
  {
    telephoneCode: "+48",
    displayName: "Poland",
    commonName: "波兰",
    countryCode: "POL",
    id: "PL",
    smsLanguage: "英文",
    nationalFlag: "🇵🇱"
  },
  {
    telephoneCode: "+351",
    displayName: "Portugal",
    commonName: "葡萄牙",
    countryCode: "PRT",
    id: "PT",
    smsLanguage: "英文",
    nationalFlag: "🇵🇹"
  },
  {
    telephoneCode: "+974",
    displayName: "Qatar",
    commonName: "卡塔尔",
    countryCode: "QAT",
    id: "QA",
    smsLanguage: "英文",
    nationalFlag: "🇶🇦"
  },
  {
    telephoneCode: "+966",
    displayName: "Saudi Arabia",
    commonName: "沙特阿拉伯",
    countryCode: "SAU",
    id: "SA",
    smsLanguage: "英文",
    nationalFlag: "🇸🇦"
  },
  {
    telephoneCode: "+65",
    displayName: "Singapore",
    commonName: "新加坡",
    countryCode: "SGP",
    id: "SG",
    smsLanguage: "英文",
    nationalFlag: "🇸🇬"
  },
  {
    telephoneCode: "+82",
    displayName: "South Korea",
    commonName: "韩国",
    countryCode: "KOR",
    id: "KR",
    smsLanguage: "英文",
    nationalFlag: "🇰🇷"
  },
  {
    telephoneCode: "+34",
    displayName: "Spain",
    commonName: "西班牙",
    countryCode: "ESP",
    id: "ES",
    smsLanguage: "英文",
    nationalFlag: "🇪🇸"
  },
  {
    telephoneCode: "+46",
    displayName: "Sweden",
    commonName: "瑞典",
    countryCode: "SWE",
    id: "SE",
    smsLanguage: "英文",
    nationalFlag: "🇸🇪"
  },
  {
    telephoneCode: "+41",
    displayName: "Switzerland",
    commonName: "瑞士",
    countryCode: "CHE",
    id: "CH",
    smsLanguage: "英文",
    nationalFlag: "🇨🇭"
  },
  {
    telephoneCode: "+886",
    displayName: "Taiwan",
    commonName: "台湾",
    countryCode: "TWN",
    id: "TW",
    smsLanguage: "英文",
    nationalFlag: "🇨🇳"
  },
  {
    telephoneCode: "+66",
    displayName: "Thailand",
    commonName: "泰国",
    countryCode: "THA",
    id: "TH",
    smsLanguage: "英文",
    nationalFlag: "🇹🇭"
  },
  {
    telephoneCode: "+971",
    displayName: "United Arab Emirates",
    commonName: "阿拉伯联合酋长国",
    countryCode: "AE",
    smsLanguage: "英文",
    nationalFlag: "🇦🇪"
  },
  {
    telephoneCode: "+44",
    displayName: "United Kingdom",
    commonName: "英国",
    id: "GB",
    countryCode: "GBR",
    smsLanguage: "英文",
    nationalFlag: "🇬🇧"
  }
];
