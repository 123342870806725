import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAssetWalletInfo,
  IAssetSituational,
  ICurrencyConfig,
  IWalletFixedYield,
  IWalletSpotAccount,
  IWalletStakingYield,
  IWalletStratgiesItem,
  IWalletStructuredAccount,
  IWalletVentureItem,
  IWalletYieldAccount,
  IAccumulatorAccountModel,
  ISnowballAccountModel
} from "@aspen/model";
import { RootState } from "../store";

interface IinitialState {
  walletInfo: IAssetWalletInfo | null;
  assetsLineData: IAssetSituational | null; // 资产曲线打点数据
  depositCoinList: ICurrencyConfig[] | null; // 可申购赎回币种列表
  isPreWithdraw: boolean; // 是否允许出金
  isShowAmount: boolean; // 金额是否隐藏
  spotAccounts: IWalletSpotAccount[] | null; // spot
  yieldFlexibleList: IWalletYieldAccount[]; // 活期
  yieldFixedList: IWalletFixedYield[]; // 定期
  yieldStakingHoldings: IWalletStakingYield[]; // staking持仓
  allStakingContractsList: IWalletStakingYield[]; // all staking
  structuredList: IWalletStructuredAccount[]; // 双币
  accumulatorList: IAccumulatorAccountModel[]; // 累计期权
  snowballList: ISnowballAccountModel[]; // 雪球
  strategyList: IWalletStratgiesItem[]; // 策略
  ventureList: IWalletVentureItem[]; // 风投
}

const initialState: IinitialState = {
  walletInfo: null,
  assetsLineData: null,
  depositCoinList: null,
  isPreWithdraw: false,
  isShowAmount: true,
  spotAccounts: null,
  yieldFlexibleList: [], // 活期
  yieldFixedList: [], // 定期
  yieldStakingHoldings: [], // staking
  allStakingContractsList: [],
  structuredList: [], // 双币
  accumulatorList: [], // 累计期权
  snowballList: [], // 雪球
  strategyList: [], // 策略
  ventureList: [] // 风投
};

// TODO
export const walletSlice: any = createSlice({
  name: "Wallet",
  initialState,
  reducers: {
    updateWalletInfo: (state, action: PayloadAction<IAssetWalletInfo>) => {
      const data = action.payload;
      if (data && data.computeTime) {
        // @ts-ignore
        delete data.computeTime;
      }
      state.walletInfo = data;
    },
    updateAssetsLineData: (state, action: PayloadAction<IAssetSituational>) => {
      state.assetsLineData = action.payload;
    },
    updateDepositCoinList: (state, action: PayloadAction<ICurrencyConfig[]>) => {
      state.depositCoinList = action.payload;
    },
    // 是否允许出金
    updateIsPreWithdraw: (state, action: PayloadAction<boolean>) => {
      state.isPreWithdraw = action.payload;
    },
    // 是否显示/隐藏金额
    updateIsShowAmount: (state, action: PayloadAction<boolean>) => {
      state.isShowAmount = action.payload;
      console.log(action.payload);
    },
    // spot
    updateSpotAccountList: (state, action: PayloadAction<IWalletSpotAccount[]>) => {
      state.spotAccounts = action.payload;
    },
    // yield flexible
    updateYieldFlexibleList: (state, action: PayloadAction<IWalletYieldAccount[]>) => {
      state.yieldFlexibleList = action.payload;
    },
    // yield fixed
    updateYieldFixedList: (state, action: PayloadAction<IWalletFixedYield[]>) => {
      state.yieldFixedList = action.payload;
    },
    // yield staking 持仓
    updateYieldStakingHoldings: (state, action: PayloadAction<IWalletStakingYield[]>) => {
      state.yieldStakingHoldings = action.payload;
    },
    // Staking Contracts List
    updateAllStakingContractsList: (state, action: PayloadAction<IWalletStakingYield[]>) => {
      state.allStakingContractsList = action.payload;
    },
    // structured
    updateStructuredList: (state, action: PayloadAction<IWalletStructuredAccount[]>) => {
      state.structuredList = action.payload;
    },
    // accumulator
    updateAccumulatorList: (state, action: PayloadAction<IAccumulatorAccountModel[]>) => {
      state.accumulatorList = action.payload;
    },
    // snowball
    updateSnowballList: (state, action: PayloadAction<ISnowballAccountModel[]>) => {
      state.snowballList = action.payload;
    },
    // strategy
    updateStrategyList: (state, action: PayloadAction<IWalletStratgiesItem[]>) => {
      state.strategyList = action.payload;
    },
    // venture
    updateVentureList: (state, action: PayloadAction<IWalletVentureItem[]>) => {
      state.ventureList = action.payload;
    },
    clearAllWallet: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    }
  }
});

export const walletInfo = (state: RootState) => state.wallet.walletInfo;
export const assetsLineData = (state: RootState) => state.wallet.assetsLineData;
export const depositCoinList = (state: RootState) => state.wallet.depositCoinList;
export const isPreWithdraw = (state: RootState) => state.wallet.isPreWithdraw;
export const showAmount = (state: RootState) => state.wallet.isShowAmount;
export const spotAccounts = (state: RootState) => state.wallet.spotAccounts;
export const yieldFlexibleList = (state: RootState) => state.wallet.yieldFlexibleList;
export const yieldFixedList = (state: RootState) => state.wallet.yieldFixedList;
export const yieldStakingHoldings = (state: RootState) => state.wallet.yieldStakingHoldings;
export const allStakingContractsList = (state: RootState) => state.wallet.allStakingContractsList;
export const strategyList = (state: RootState) => state.wallet.strategyList;
export const structuredList = (state: RootState) => state.wallet.structuredList;
export const accumulatorList = (state: RootState) => state.wallet.accumulatorList;
export const snowballList = (state: RootState) => state.wallet.snowballList;
export const ventureList = (state: RootState) => state.wallet.ventureList;

export const {
  updateWalletInfo,
  updateAssetsLineData,
  updateDepositCoinList,
  updateIsPreWithdraw,
  updateIsShowAmount,
  updateSpotAccountList,
  updateYieldFlexibleList,
  updateYieldFixedList,
  updateYieldStakingHoldings,
  updateAllStakingContractsList,
  updateStructuredList,
  updateAccumulatorList,
  updateSnowballList,
  updateStrategyList,
  updateVentureList,
  clearAllWallet
} = walletSlice.actions;

export default walletSlice.reducer;
