import React, { useState } from "react";
import { dateFormat, EVENTS_PATHS, GA_EVENT_NAME, getEventStatus, reportEvent, toLocaleTime } from "@aspen/libs";
import { EventTag } from "./EventTag";
import { CountdownView } from "./CountdownView";
import styles from "@aspen/theme/Event.module.less";
import variables from "@aspen/theme/variables.module.less";
import { useRouter } from "next/router";
import { ConferenceVO, IEventStatus } from "@aspen/model";
import Image from "next/image";
import clsx from "clsx";
import dayjs from "dayjs";

const pos = require("@aspen/assets/images/event/event_ic_pos.webp");
const time = require("@aspen/assets/images/event/event_ic_time.webp");

interface IProps {
  item: ConferenceVO;
  isDetail?: boolean;
}

export const EventListItem = (props: IProps) => {
  const router = useRouter();
  const {item, isDetail = false} = props;
  const [status, setStatus] = useState<IEventStatus>(getEventStatus(item?.startTime, item?.endTime));
  const now = new Date().getTime();
  const handleClick = () => {
    if (item.detailType === "LOCAL") {
      item?.id && router.push({
        pathname: `${EVENTS_PATHS.EVENTS_DETAIL}/${item?.id}`
      });
    } else {
      item?.externalLink && window.open(item?.externalLink)
      reportEvent({
        moduleName: GA_EVENT_NAME.event.openOuterLink, detailParams: {
          link: item?.externalLink || ""
        }
      });
    }
  };

  //开始结束时间是否跨天
  const isSkipDay =
    toLocaleTime(item?.startTime, dateFormat) != toLocaleTime(item.endTime, dateFormat);
  return (
    <div
      className={styles.eventListItem}
      style={{marginRight: isDetail ? 0 : 22, background: isDetail ? variables.bgTransparent : variables.mainBgColor}}>
      <div
        style={{position: "relative", borderRadius: 4, overflow: "hidden"}}
        onClick={handleClick}>
        {item?.image ? (
          <div style={{position: 'relative'}}>
            {status === "coming" ? <div className={styles.eventItemImageBg}/> : null}
            <Image unoptimized src={item?.image} alt=""
                   width={368} height={160} style={{objectFit: 'cover', objectPosition: "center"}}/>
          </div>
        ) : null}
        <div className={isDetail ? styles.innerContent : styles.content}>
          <span className={clsx(styles.title)}>{item?.title}</span>
          {!isDetail ? <span className={clsx(styles.summary)}>{item?.summary}</span> : null}
          {!isDetail ? (
            <>
              {item?.location ? <div className={styles.info}>
                <Image unoptimized src={pos} alt="" width={12} height={12}/>
                <span className={clsx(styles.location)}>{item?.location}</span>
              </div> : null}
              {item.startTime && item.endTime ? <div className={styles.info} style={{marginBottom:0}}>
                <Image unoptimized src={time} alt="" width={12} height={12}/>
                <span>
                  {`${toLocaleTime(item.startTime, "YYYY.MM.DD HH:mm")} - ${toLocaleTime(
                    item.endTime,
                    isSkipDay ? "MM.DD HH:mm" : "HH:mm"
                  )} (UTC${dayjs().format("Z")})`}
                </span>
              </div> : null}
            </>
          ) : (
            <div className={styles.info} style={{marginBottom: 0}}>
              <span style={{marginLeft: 0, marginTop: 8, color: variables.opWhite8}}>
               {item.location ? <span style={{marginLeft: 0}}>{item.location} | {" "}</span> : null}
                {`${toLocaleTime(item.startTime, "YYYY.MM.DD HH:mm")} - ${toLocaleTime(
                  item.endTime,
                  isSkipDay ? "MM.DD HH:mm" : "HH:mm"
                )} (UTC${dayjs().format("Z")})`}
              </span>
            </div>
          )}
        </div>
        <EventTag status={status} style={{position: "absolute", left: 0, top: 0}}/>
        {status === "coming" ? (
          <div style={{position: "absolute", right: 12, top: 108, zIndex: 11}}>
            <CountdownView key={item?.id} startTime={now} endTime={item.startTime} countStatusEnd={() => {
              setStatus(getEventStatus(item.startTime, item.endTime))
            }}/>
          </div>
        ) : null}
      </div>
    </div>
  );
};
