/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { Empty, Spin } from "antd";
import styles from "@aspen/theme/Event.module.less";
import { GA_EVENT_NAME, i18nUtil, reportEvent } from "@aspen/libs";
import { COMMON_CACHE_PRIORTY_KEYS, ConferenceVO, EventsTypeModel, IEventsListReq } from "@aspen/model";
import { EventListItem } from "./EventListItem";
import { LoadMore } from "../intelligence/DynamicImport";
import { useCacheDataPriority } from "@aspen/hooks";

interface IProps {
  type?: EventsTypeModel;
}

const EventListView: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const [list, setList] = useState<ConferenceVO[]>([]);
  const [nextToken, setNextToken] = useState<string>("");
  const [params, setParams] = useState<IEventsListReq>({
    limit: 12,
    type: props?.type || "",
    nextToken: ""
  });
  const {data: eventList, isLoading} = useCacheDataPriority({
    key: COMMON_CACHE_PRIORTY_KEYS.eventsList,
    params: params
  });
  useEffect(() => {
    if (eventList?.rows) {
      const newData = eventList.rows ?? [];
      setList(nextToken ? list?.concat(newData) : newData);
      setNextToken(eventList?.nextToken);
    }
  }, [eventList]);

  const handleLoadMore = useCallback(() => {
    reportEvent({
      moduleName: GA_EVENT_NAME.event.loadMore,
      detailParams: {type: props?.type || ""}
    });
    setParams({
      ...params,
      nextToken
    });
  }, [props?.type, nextToken]);

  return (
    <Spin spinning={isLoading}>
      <div className={styles.event} style={{marginTop: "34px"}}>
        {list && list?.length > 0 ? (
          <div className={styles.eventList}>
            {list?.map((item, index) => <EventListItem item={item} key={index}/>)}
          </div>
        ) : (
          <div className={styles.eventEmpty}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={intl["empty.list"]}/>
          </div>
        )}
      </div>
      <LoadMore loading={isLoading} noMore={!nextToken} handleLoadMore={handleLoadMore}/>
    </Spin>
  );
};

export const EventList = React.memo(EventListView);
