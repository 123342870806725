import React from "react";

import { Modal, Button, Row, Col } from "antd";
import { WithTrimInput } from "@aspen/ui/withTrimInput";
import styles from "./ModalSafetyVerification.module.less";
import { EmailCodeBaseProps, EmailCodeLayout } from "@aspen/ui";
import {
  OPERATE_CUSTOMER_EMAIL,
  USER,
  USER_ROLE,
  USER_AUTH,
  USER_ROLE_POWER,
  VerifyCode_MAX_LENGTH,
  i18nUtil
} from "@aspen/libs";
import { ModalLoadingContext } from "@aspen/store";

const defaultProps = {
  // 是否不调用 验证邮箱验证码接口，
  // 比如拿到邮箱验证码自行处理，不需要调用邮箱验证码接口，
  isCustomerValidate: false
};
interface IProps extends EmailCodeBaseProps {
  visible: boolean;
  handleCancel: () => void;
  onOk: (verifyCode?: string) => void;
  businessType?: number;
  isCustomerValidate: false;
  confirmLoading?: boolean;
  verifyCodeToSelf?: boolean; // 代客操作时，若要发送验证码给子账户/代理商，此参数需传ture
}

interface IState {
  disabled: boolean;
  verifyCode: string;
}

class ModalSafetyVerification extends React.PureComponent<IProps, IState> {
  static contextType = ModalLoadingContext;

  constructor(props) {
    super(props);
    this.state = {
      verifyCode: "",
      disabled: true
    };
  }
  handleOk: () => void = async () => {
    if (this.state.verifyCode == "") {
      return;
    }
    // 不需要调用 验证邮箱接口，返回输入的邮箱验证码给调用者自己处理
    if (this.props.isCustomerValidate) {
      this.props.onOk(this.state.verifyCode);
      return;
    }
    // 暂时弃用 接口已删除 若要启用需后端配合
    const res = await this.props.verifyEmailInputCode(this.state.verifyCode);
    if (res?.consecutiveErrors) {
      // 错误后将输入的验证码从inputbox中清空
      return this.setState({
        verifyCode: ""
      });
    }
    if (res?.verifyStatus == "0") {
      this.props.onOk();
      this.props.handleCancel();
    }
  };

  clickOnhandleCancel: () => void = () => {
    this.props.handleCancel();
  };

  setVerificationCode(e: React.ChangeEvent<HTMLInputElement>): void {
    const val = e.target.value;
    const onlyNumber = val.replace(/[^0-9]/gi, "");
    this.setState({
      verifyCode: onlyNumber
    });
  }

  render(): React.ReactNode {
    const { businessType, verifyCodeToSelf } = this.props;
    // @ts-ignore
    const { confirmLoading } = this.context;
    const user_auth = typeof window != "undefined" && localStorage.getItem(USER_AUTH);
    const operateCustomerEmail =
      (typeof window != "undefined" && localStorage.getItem(OPERATE_CUSTOMER_EMAIL)) || "";
    const user = (typeof window != "undefined" && localStorage.getItem(USER)) || "";
    const intl = i18nUtil.t();
    const okText: string = `${intl["button.confirm"]}`;
    const cancelText: string = `${intl["button.cancel"]}`;
    //代客操作时 邮箱验证文案不同 如果是子账号与其他代客操作也不同
    const content1: string =
      // @ts-ignore
      USER_ROLE_POWER[user_auth]?.actionForOthers && !verifyCodeToSelf
        ? user_auth == USER_ROLE.ROLE_SUB
          ? intl["profile.client.email.verification1.sub"]
          : intl["profile.client.email.verification1"]
        : intl["profile.email.verification1"];
    const content2: string = intl["profile.email.verification2"];
    const title: string = intl["profile.basic.info.google.auth.step2.title"];
    // 若verifyCodeToSelf为true：发验证码给当前账号
    // 若没有或者为false时：代客操作则发送给主账号，客户登录的话发送给当前客户
    const email: string = (verifyCodeToSelf ? user : operateCustomerEmail) || user;
    const placeholder: string = intl["profile.google.verification2.verfy.placeholder"];

    return (
      <Modal
        destroyOnClose
        afterClose={() => {
          this.setState({
            verifyCode: ""
          });
        }}
        wrapClassName={styles.modal}
        title={title}
        open={this.props.visible}
        confirmLoading={confirmLoading || this.props.confirmLoading}
        onOk={() => this.handleOk()}
        centered
        okText={okText}
        cancelText={cancelText}
        width={480}
        onCancel={this.clickOnhandleCancel}>
        <p className={styles.desc}>
          {content1}
          <span className={styles.emphasize}> ****{email?.slice(2)}</span>
          <br />
          {content2}
        </p>
        <Row>
          <Col flex="auto">
            <WithTrimInput
              placeholder={placeholder}
              value={this.state.verifyCode}
              maxLength={VerifyCode_MAX_LENGTH}
              type="text"
              autoComplete="off"
              onChange={(e) => this.setVerificationCode(e)}
            />
          </Col>
          <Col flex="110px">
            <Button
              disabled={this.props.countdown != 60}
              className={styles.sendEmail}
              loading={this.props.loading}
              onClick={() => this.props.getEmailCode(businessType ? businessType : 13, email)}>
              {this.props.sendMailBtn}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}
// @ts-ignore
export default EmailCodeLayout(ModalSafetyVerification);
