import {
  PARTNER_PATH,
  HOME_PATH,
  TRADE_PATH_DEFAULT_PATH,
  MANAGER_PATH
} from "./routerPaths/routingConstantConfig";

// 账户权限 ，目前登陆后，后台仅会返回一种角色
export const USER_ROLE = {
  ROLE_CUSTOMER: "customer",
  ROLE_PARTNER: "partner",
  ROLE_MANAGER: "manager",
  ROLE_PARTNER_CUSTOMER: "partnercustomer", // 代理商进入客户页面操作
  ROLE_MANAGER_CUSTOMER: "managercustomer", // 管理员进入客户页面操作
  ROLE_MANAGER_READONLY: "managerreadonly", // 管理员只读权限
  ROLE_SUB: "subcustomer"
};
/**
 * 角色权限功能
 * depositAndWithdraw：是否开启 充提币、出入金 入口
 * loginToPath：登陆后默认进入的路由
 * actionForOthers：当前角色是否是 正在为客户进行操作（目前仅判断是否全局提示蓝条）
 */
export const USER_ROLE_POWER = {
  [USER_ROLE.ROLE_CUSTOMER]: {
    depositAndWithdraw: true,
    loginToPath: HOME_PATH,
    actionForOthers: false,
    withdrawApplication: false
  },
  [USER_ROLE.ROLE_PARTNER]: {
    depositAndWithdraw: false,
    loginToPath: PARTNER_PATH.DASHBOARD,
    actionForOthers: false,
    withdrawApplication: false
  },
  // 管理员权限
  [USER_ROLE.ROLE_MANAGER]: {
    depositAndWithdraw: false,
    loginToPath: MANAGER_PATH.CUSTOMER_MANAGEMENT,
    actionForOthers: false,
    withdrawApplication: false
  },
  // partnercustomer为代理商代理客户操作时的权限
  [USER_ROLE.ROLE_PARTNER_CUSTOMER]: {
    depositAndWithdraw: false,
    loginToPath: TRADE_PATH_DEFAULT_PATH,
    actionForOthers: true,
    withdrawApplication: false
  },
  // 管理员代理客户操作时的权限
  [USER_ROLE.ROLE_MANAGER_CUSTOMER]: {
    depositAndWithdraw: false,
    loginToPath: TRADE_PATH_DEFAULT_PATH,
    actionForOthers: true,
    withdrawApplication: false
  },
  // 管理员只读权限
  [USER_ROLE.ROLE_MANAGER_READONLY]: {
    depositAndWithdraw: false,
    loginToPath: TRADE_PATH_DEFAULT_PATH,
    actionForOthers: true,
    withdrawApplication: false
  },
  [USER_ROLE.ROLE_SUB]: {
    depositAndWithdraw: true,
    loginToPath: TRADE_PATH_DEFAULT_PATH,
    actionForOthers: true,
    withdrawApplication: true
  }
};

// 用户角色类型
export const USER_ACCOUNT_TYPE = {
  customer: "CUSTOMER",
  partner: "AGENT",
  manager: "MANAGE_ACCOUNT",
  subcustomer: "SUB_ACCOUNT"
};

export const HEADER_CUSTOMER_ROLE = {
  resetShow: false, // 重置密码只有 子账号和帮客时显示，其它都不显示
  profileShow: true, // 个人中心只有普通用户时显示
  onboardingShow: false, //  帮客操作时 显示此下拉框 帮客kyc circle
  avatarShow: true, // 右上角头像是否显示，代客操作、帮客操作时 不显示
  partnerMenuShow: false, // cms Menu
  QRCodeShow: true, // 二维码展示 仅customer
  languageShow: true, // cms不显示语言切换
  cmsTitleShow: false, // 是否显示cmsTitle
  isAffix: false, // cms固定header
  defaultPath: HOME_PATH // 登陆后跳转的路由
};

export const HEADER_SUB_ROLE = {
  resetShow: true, // 重置密码只有 子账号和帮客时显示，其它都不显示
  profileShow: false, // 个人中心只有普通用户时显示
  onboardingShow: false, //  帮客操作时 显示此下拉框 帮客kyc circle
  avatarShow: true, // 右上角头像是否显示，代客操作、帮客操作时 不显示
  partnerMenuShow: false, // cms Menu
  QRCodeShow: false, // 二维码展示 仅customer
  languageShow: true, // cms不显示语言切换
  cmsTitleShow: false, // 是否显示cmsTitle
  isAffix: false, // cms固定header
  defaultPath: TRADE_PATH_DEFAULT_PATH // 登陆后跳转的路由
};

export const HEADER_PARTNER_ROLE = {
  resetShow: false, // 重置密码只有 子账号和帮客时显示，其它都不显示
  profileShow: false, // 个人中心只有普通用户时显示
  onboardingShow: false, //  帮客操作时 显示此下拉框 帮客kyc circle
  avatarShow: true, // 右上角头像是否显示，代客操作、帮客操作时 不显示
  partnerMenuShow: true, // cms Menu
  QRCodeShow: false, // 二维码展示 仅customer
  languageShow: false, // cms不显示语言切换
  cmsTitleShow: true, // 是否显示cmsTitle
  isAffix: true, // cms固定header
  defaultPath: PARTNER_PATH.DASHBOARD // 登陆后跳转的路由
};

export const HEADER_MANAGER_ROLE = {
  resetShow: true, // 重置密码只有 子账号和帮客时显示，其它都不显示
  profileShow: false, // 个人中心只有普通用户时显示
  onboardingShow: false, //  帮客操作时 显示此下拉框 帮客kyc circle
  avatarShow: true, // 右上角头像是否显示，代客操作、帮客操作时 不显示
  partnerMenuShow: false, // cms Menu
  QRCodeShow: false, // 二维码展示 仅customer
  languageShow: true, // cms不显示语言切换
  cmsTitleShow: false, // 是否显示cmsTitle
  isAffix: false, // cms固定header
  defaultPath: MANAGER_PATH.CUSTOMER_MANAGEMENT // 登陆后跳转的路由
};

export const HEADER_MANAGER_CUSTOMER_ROLE = {
  resetShow: false, // 重置密码只有 子账号和帮客时显示，其它都不显示
  profileShow: false, // 个人中心只有普通用户时显示
  onboardingShow: true, //  帮客操作时 显示此下拉框 帮客kyc circle
  avatarShow: false, // 右上角头像是否显示，代客操作、帮客操作时 不显示
  partnerMenuShow: false, // cms Menu
  QRCodeShow: false, // 二维码展示 仅customer
  languageShow: true, // cms不显示语言切换
  cmsTitleShow: false, // 是否显示cmsTitle
  isAffix: false, // cms固定header
  defaultPath: TRADE_PATH_DEFAULT_PATH // 登陆后跳转的路由
};
export const HEADER_PARTNER_CUSTOMER_ROLE = {
  resetShow: false, // 重置密码只有 子账号和帮客时显示，其它都不显示
  profileShow: false, // 个人中心只有普通用户时显示
  onboardingShow: false, //  帮客操作时 显示此下拉框 帮客kyc circle
  avatarShow: false, // 右上角头像是否显示，代客操作、帮客操作时 不显示
  partnerMenuShow: false, // cms Menu
  QRCodeShow: false, // 二维码展示 仅customer
  languageShow: true, // cms不显示语言切换
  cmsTitleShow: false, // 是否显示cmsTitle
  isAffix: false, // cms固定header
  defaultPath: TRADE_PATH_DEFAULT_PATH // 登陆后跳转的路由
};
