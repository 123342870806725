import { IEventStatus } from "@aspen/model";

export const getEventStatus = (startTime: number | undefined, endTime: number | undefined) => {
  let status: IEventStatus = "";
  const _current = new Date().getTime();
  if(startTime && endTime) {
    if (_current < startTime) {
      //活动未开始
      status = "coming";
    } else if (_current > endTime) {
      //活动结束
      status = "end";
    } else {
      //活动中
      status = "inProgress";
    }
  }
  return status;

};
