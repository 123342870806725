import React, { useEffect, useRef, useState } from "react";
import { Empty, Spin } from "antd";
import styles from "@aspen/theme/Event.module.less";
import { i18nUtil } from "@aspen/libs";
import { EventListItem } from "./EventListItem";
import { ConferenceVO } from "@aspen/model";
import { fetchEventsList } from "@aspen/services";

interface IProps {
  curId: string;
  setHeight?: (h: number) => void;
}

const EventMoreView: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const {curId, setHeight} = props;
  const dHeight = useRef<any>(null);
  const tarIndex = useRef<number | undefined>(undefined);
  const [filteredEventList, setFilteredEventList] = useState<ConferenceVO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    fetchEventsList({limit: 4, type: ""}).then((res) => {
      if (res?.code == "0") {
        if (res?.data?.rows) {
          let list = res.data.rows.slice() || []
          if (curId) {
            list?.map((item, index) => {
              if (item?.id === curId) {
                // @ts-ignore
                tarIndex.current = index;
              }
            })
          };
          if (tarIndex) {
            list.splice(tarIndex.current, 1)
          } else {
            list.splice(list.length - 1, 1)
          }
          setFilteredEventList(list)
        }
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [curId]);
  useEffect(() => {
    setHeight && setHeight(dHeight?.current.clientHeight)
  }, [isLoading])

  return (
    <Spin spinning={isLoading}>
      <div className={styles.eventMore} ref={dHeight}>
        {filteredEventList && filteredEventList?.length > 0 ? (
          <>
            {filteredEventList?.map((item, index) => (
              <EventListItem item={item} isDetail={true} key={index}/>
            ))}
          </>
        ) : (
          <div className={styles.eventEmpty}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={intl["empty.list"]}/>
          </div>
        )}
      </div>
    </Spin>
  );
};
export const EventMore = React.memo(EventMoreView);
