"use client";
import React from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import styles from "@aspen/theme/Home.module.less";
import { IAssetWalletInfo, IInjectProps, IKycLevel } from "@aspen/model";
import {
  numberToThousands,
  decimalPointNoMoreX,
  getPNLColor,
  i18nUtil,
  ASSETANALYSIS,
  BENCHMARK_CURRENCY_SYMBOL,
  REFERENCE_CURRENCY,
  ASSETS_PATHS
} from "@aspen/libs";
import { withRouter } from "next/router";
import { TotalAssetsChart } from "./totalAssetsChart/TotalAssetsChart";
import { isEqual } from "lodash-es";

interface IProps extends IInjectProps {
  walletInfo: IAssetWalletInfo;
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}
interface IState {
  showAssets: boolean;
}
// (现货 理财 结构化理财 策略 风投)颜色
const colorArr = {
  spot: "#3987CF",
  yield: "#A646B6",
  structured: "#E5B60B",
  strategies: "#F17E00",
  ventures: "#64A797",
  vault: "#F67070"
};
class AssetDistributionView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showAssets: true
    };
    this.handleToggleAssets = this.handleToggleAssets.bind(this);
  }

  handleToAssets = () => {
    if (this.props.showGuideModal(1)) {
      this.props.router.push(ASSETS_PATHS.ASSETS);
    }
  };
  handleToggleAssets(flag): void {
    this.setState({
      showAssets: flag
    });
  }
  componentDidMount(): void {}

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { walletInfo } = this.props;
    const title: string = i18nUtil.formatMessage(
      { id: "overview.assetDistribution.title" },
      { currency: REFERENCE_CURRENCY }
    );
    const spotText: string = intl["overview.assetDistribution.spot"];
    const yieldText: string = intl["overview.assetDistribution.yield"];
    const structuredText = intl["overview.assetDistribution.structured"];
    const strategiesText: string = intl["overview.assetDistribution.strategies"];
    const venturesText: string = intl["overview.assetDistribution.ventures"];
    const vaultText: string = intl["overview.assetDistribution.vault"];
    const toAssets: string = intl["overview.assetDistribution.to.assets"];
    const totalAssets: string = intl["assets.total.text"];
    const totalPNL = intl["assets.pnl.total"];
    const todayPNL = intl["assets.pnl.today"];
    const { showAssets } = this.state;
    const pieList = [
      {
        title: spotText,
        value: Number(walletInfo?.spotAmount)
      },
      {
        title: yieldText,
        value: Number(walletInfo?.yieldAmount)
      },
      {
        title: structuredText,
        value: Number(walletInfo?.structuredAmount)
      },
      {
        title: strategiesText,
        value: Number(walletInfo?.strategyAmount)
      },
      {
        title: venturesText,
        value: Number(walletInfo?.ventureAmount)
      },
      {
        title: vaultText,
        value: Number(walletInfo?.coldWalletAmount)
      }
    ];
    const progressList = [
      {
        title: spotText,
        value:
          BENCHMARK_CURRENCY_SYMBOL +
          (numberToThousands(decimalPointNoMoreX(walletInfo?.spotAmount, 2)) ?? 0),
        percent: Math.ceil(
          (Number(walletInfo?.spotAmount) / Number(walletInfo?.totalAmount)) * 100
        ),
        color: colorArr.spot
      },
      {
        title: yieldText,
        value:
          BENCHMARK_CURRENCY_SYMBOL +
          (numberToThousands(decimalPointNoMoreX(walletInfo?.yieldAmount, 2)) ?? 0),
        percent: Math.ceil(
          (Number(walletInfo?.yieldAmount) / Number(walletInfo?.totalAmount)) * 100
        ),
        color: colorArr.yield
      },
      {
        title: structuredText,
        value:
          BENCHMARK_CURRENCY_SYMBOL +
          (numberToThousands(decimalPointNoMoreX(walletInfo?.structuredAmount, 2)) ?? 0),
        percent: Math.ceil(
          (Number(walletInfo?.structuredAmount) / Number(walletInfo?.totalAmount)) * 100
        ),
        color: colorArr.structured
      },
      {
        title: strategiesText,
        value:
          BENCHMARK_CURRENCY_SYMBOL +
          (numberToThousands(decimalPointNoMoreX(walletInfo?.strategyAmount, 2)) ?? 0),
        percent: Math.ceil(
          (Number(walletInfo?.strategyAmount) / Number(walletInfo?.totalAmount)) * 100
        ),
        color: colorArr.strategies
      },
      {
        title: venturesText,
        value:
          BENCHMARK_CURRENCY_SYMBOL +
          (numberToThousands(decimalPointNoMoreX(walletInfo?.ventureAmount, 2)) ?? 0),
        percent: Math.ceil(
          (Number(walletInfo?.ventureAmount) / Number(walletInfo?.totalAmount)) * 100
        ),
        color: colorArr.ventures
      },
      {
        title: vaultText,
        value:
          BENCHMARK_CURRENCY_SYMBOL +
          (numberToThousands(decimalPointNoMoreX(walletInfo?.coldWalletAmount, 2)) ?? 0),
        percent: Math.ceil(
          (Number(walletInfo?.coldWalletAmount) / Number(walletInfo?.totalAmount)) * 100
        ),
        color: colorArr.vault
      }
    ];
    const showAssetAnalysis =
      typeof window != "undefined" && localStorage.getItem(ASSETANALYSIS) === "true";
    return (
      <Spin spinning={!walletInfo}>
        <section className={styles.assetDisbution}>
          <p className={styles.title}> {title} </p>
          <div className={styles.assetDisbutionChart}>
            <TotalAssetsChart
              showAssets={showAssets}
              totalNumber={walletInfo?.totalAmount}
              totalText={totalAssets}
              pieList={pieList}
              handleToggleAssets={(show: boolean) => {
                this.handleToggleAssets(show);
              }}
              width={220}
              color={Object.values(colorArr)}
            />
          </div>
          <div className={styles.assetDisbutionProgess}>
            {progressList?.map((item) => (
              <div
                key={item.title}
                className={styles.progressCard}
                style={{ borderColor: item.color }}>
                <div className={styles.divider} style={{ background: item.color }} />
                <div>
                  <p className={styles.name}>{item.title}</p>
                  {showAssets ? <span className={styles.value}>{item?.value}</span> : "****"}
                </div>
              </div>
            ))}
          </div>
          {showAssetAnalysis ? (
            <div className={styles.pnlArea}>
              <div className={styles.pnlText}>
                <div className={styles.pnlTitle}>{totalPNL}</div>
                {showAssets ? (
                  <div
                    className={styles.pnlValue}
                    style={{ color: getPNLColor(walletInfo?.totalRevenue) }}>
                    {walletInfo?.totalRevenue > 0 && "+"}
                    {numberToThousands(decimalPointNoMoreX(walletInfo?.totalRevenue, 2))}
                  </div>
                ) : (
                  "****"
                )}
              </div>
              <div className={styles.pnlText}>
                <div className={styles.pnlTitle}>{todayPNL}</div>
                {showAssets ? (
                  <div
                    className={styles.pnlValue}
                    style={{ color: getPNLColor(walletInfo?.todayRevenue) }}>
                    {walletInfo?.todayRevenue !== undefined && walletInfo?.todayRevenue > 0 && "+"}
                    {walletInfo?.todayRevenue !== undefined
                      ? numberToThousands(decimalPointNoMoreX(walletInfo?.todayRevenue, 2))
                      : "--"}
                  </div>
                ) : (
                  "****"
                )}
              </div>
            </div>
          ) : (
            <div className={styles.pnlArea} />
          )}
          {showAssetAnalysis ? (
            <div className={styles.toBtn}>
              <span onClick={this.handleToAssets}>{toAssets} &gt;</span>
            </div>
          ) : (
            <div className={styles.toBtn} />
          )}
        </section>
      </Spin>
    );
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    return (
      !isEqual(nextProps.walletInfo, this.props.walletInfo) ||
      nextState.showAssets != this.state.showAssets
    );
  }
}

const mapStateToProps = (state) => {
  const { walletInfo } = state.wallet;
  return {
    walletInfo: walletInfo
  };
};
export const AssetDistribution = connect(mapStateToProps)(withRouter(AssetDistributionView));
