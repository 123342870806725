/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
// eslint-disable-next-line no-unused-vars
import { RootState, AppThunk } from "../store";
import {
  IOrderBook,
  IPrecisionItem,
  IPrecisions,
  ISymbolPrecisions,
  ISymbolInfo,
  ISymbolData,
  IWalletSpotAccount
} from "@aspen/model/index";
import { decimalPointNoMoreX } from "@aspen/libs/index";
interface IinitialState {
  marketSymbolsList: ISymbolInfo;
  offlineSymbolsList: ISymbolInfo;
  favoritesSymbolsName: string[];
  holdingAssetsList: IWalletSpotAccount[];
  symbolNames: string[];
  symbolSlippages: Record<string, number[]>;
  openOrder: ISymbolData | null;
  orderBook: IOrderBook;
  depthData: IOrderBook;
  activeSymbolPrecisions: ISymbolPrecisions;
  // 为保证ob和盘口展示的最新价格同步，值从store取，取值 = (买一+卖一)/2
  currentPriceLast: string;
}
const initialState: IinitialState = {
  activeSymbolPrecisions: {},
  marketSymbolsList: {},
  offlineSymbolsList: {},
  favoritesSymbolsName: [],
  holdingAssetsList: [],
  symbolNames: [],
  symbolSlippages: {},
  openOrder: null,
  orderBook: {
    ts: 0,
    symbol: "",
    asks: [],
    bids: []
  },
  depthData: {
    bids: [],
    asks: []
  },
  currentPriceLast: ""
};

const getMidPrice = (state, orderBook: IOrderBook, priceLast?: string | number): string => {
  const symbol = state.orderBook.symbol;
  const tradePrecision = state.marketSymbolsList[symbol]?.tradePrecision;
  const ask = Number(orderBook?.asks?.[9]?.p ?? 0); // 卖一
  const bid = Number(orderBook?.bids?.[0]?.p ?? 0); // 买一
  const midPrice =
    ask + bid == 0
      ? decimalPointNoMoreX(priceLast, tradePrecision)
      : decimalPointNoMoreX((ask + bid) / 2, tradePrecision);
  return midPrice.toString();
};
// TODO
export const exchangeSlice: any = createSlice({
  name: "symbol",
  initialState,
  reducers: {
    updateSymbolInfo: (state, action) => {
      state.marketSymbolsList = { ...state.marketSymbolsList, ...action.payload.symbolInfo };
      // if (action.payload?.isWsMessage) {
      //   const marketSymbolsList = JSON.parse(JSON.stringify(state.marketSymbolsList));
      //   const pushedSymbolInfo = action.payload?.symbolInfo;
      //   const pushedSymbol: string = pushedSymbolInfo.symbol;
      //   marketSymbolsList[pushedSymbol].priceLast = pushedSymbolInfo.mid;
      //   marketSymbolsList[pushedSymbol].volume = pushedSymbolInfo.volume;
      //   marketSymbolsList[pushedSymbol].highPrice = pushedSymbolInfo.high;
      //   marketSymbolsList[pushedSymbol].lowPrice = pushedSymbolInfo.low;
      //   marketSymbolsList[pushedSymbol].ask = pushedSymbolInfo.ask;
      //   marketSymbolsList[pushedSymbol].bid = pushedSymbolInfo.bid;
      //   marketSymbolsList[pushedSymbol].riseAndFall = pushedSymbolInfo.percentage;
      //   if (JSON.stringify(marketSymbolsList) != JSON.stringify(state.marketSymbolsList))
      //     state.marketSymbolsList = marketSymbolsList;
      // } else {
      //   state.marketSymbolsList = action.payload?.symbolInfo;
      // }
    },
    updateOfflineSymbolInfo: (
      state,
      action: PayloadAction<{ offlineSymbolInfoList: ISymbolInfo }>
    ) => {
      state.offlineSymbolsList = action.payload?.offlineSymbolInfoList;
    },
    updateFavoritesSymbolsName: (
      state,
      action: PayloadAction<{ favoritesSymbolsName: string[] }>
    ) => {
      state.favoritesSymbolsName = action.payload?.favoritesSymbolsName;
    },
    updateHoldingAssetsList: (
      state,
      action: PayloadAction<{ holdingAssetsList: IWalletSpotAccount[] }>
    ) => {
      state.holdingAssetsList = action.payload?.holdingAssetsList;
    },
    updateSymbolNames: (
      state,
      action: PayloadAction<{
        symbolNames: Array<string>;
      }>
    ) => {
      state.symbolNames = action.payload?.symbolNames;
    },
    // 更新盘口滑点
    updateSymbolSlippages: (
        state,
        action: PayloadAction<{
          symbolSlippages: Record<string, number[]>;
        }>
    ) => {
      state.symbolSlippages = action.payload.symbolSlippages;
    },
    updateOrderBook: (state, action: PayloadAction<{ orderBook: IOrderBook }>) => {
      state.orderBook = action.payload?.orderBook;
      state.currentPriceLast = getMidPrice(state, action.payload?.orderBook);
    },
    updateDepth: (state, action: PayloadAction<{ depthData: IOrderBook }>) => {
      state.depthData = action.payload?.depthData;
    },
    updateOpenOrder: (state, action: PayloadAction<ISymbolData>) => {
      state.openOrder = action.payload;
    },
    // 更新上架盘口精度，盘口精度基本不变
    updateAllSymbolPrecision: (state, action: PayloadAction<Array<IPrecisions>>) => {
      let symbolPrecisions: IPrecisionItem = {};
      const data = action.payload;
      data.map((item) => {
        const info: IPrecisions = {
          coinCode: item.coinCode, // 盘口名称
          volumePrecision: item.volumePrecision,
          tradeVolumePrecision: item.tradeVolumePrecision, // 成交量实际精度
          symbolPrecision: item.symbolPrecision, // 市场价格展示精度
          tradePrecision: item.tradePrecision, // 价格输入精度
          tradeInputPrecision: item.tradeInputPrecision // 交易币输入精度
        };
        symbolPrecisions[item.coinCode] = info;
      });
      state.activeSymbolPrecisions = symbolPrecisions;
    }
  }
});

export const {
  updateSymbolInfo,
  updateSymbolSlippages,
  updateOfflineSymbolInfo,
  updateFavoritesSymbolsName,
  updateHoldingAssetsList,
  updateOrderBook,
  updateDepth,
  updateOpenOrder,
  updateSymbolNames,
  updateAllSymbolPrecision
} = exchangeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const marketSymbolsList = (state: RootState) =>
  state.marketSpotAndTradeInfo.marketSymbolsList;
export const offlineSymbolsList = (state: RootState) =>
  state.marketSpotAndTradeInfo.offlineSymbolsList;
export const favoritesSymbolsName = (state: RootState) =>
  state.marketSpotAndTradeInfo.favoritesSymbolsName;
export const holdingAssetsList = (state: RootState) =>
  state.marketSpotAndTradeInfo.holdingAssetsList;
export const symbolNames = (state: RootState) => state.marketSpotAndTradeInfo.symbolNames;
export const orderBook = (state: RootState) => state.marketSpotAndTradeInfo.orderBook;
export const depthData = (state: RootState) => state.marketSpotAndTradeInfo.depthData;
export const openOrder = (state: RootState) => state.marketSpotAndTradeInfo.openOrder;
export const activeSymbolPrecisions = (state: RootState) =>
  state.marketSpotAndTradeInfo.activeSymbolPrecisions;

export const symbolSlippages = (state: RootState) =>
  state.marketSpotAndTradeInfo.symbolSlippages;

// exports a reducer function for the counter logic.
export default exchangeSlice.reducer;
