import React from "react";
import styles from "@aspen/theme/Event.module.less";
import { ConferenceVO, IEventStatus } from "@aspen/model";
import Image from "next/image";
import { CountdownView } from "./CountdownView";
import bannerBg from "@aspen/assets/images/event/bg_image.webp"
import SkeletonImage from "antd/lib/skeleton/Image";

interface IProps {
  data: ConferenceVO;
  status: IEventStatus | undefined;
}

const EventBannerView: React.FC<IProps> = (props) => {
  const now = new Date().getTime();
  const {data, status} = props;
  return (
    <div className={styles.appBanner}>
      {data?.image ? <Image unoptimized src={data?.image || ""} alt="" fill={true} objectFit={'cover'}/> :
        <SkeletonImage style={{width: 375, height: 200, background: '#1F212F'}}/>}
      {status === "coming" ?
        <div className={styles.absoluteFull}>
          <Image unoptimized src={bannerBg} alt="" fill={true}/>
          <div className={styles.container}>
            <div className={styles.timerContainer}>
              <CountdownView startTime={now} endTime={data.startTime || 0} size={"normal"}/>
            </div>
          </div>
        </div>
        : null
      }

    </div>
  );
};
export const AppEventBanner = React.memo(EventBannerView);
