import { getEnvConfig } from "./common";
import { APP_STORE_ID } from "../config/hosts/index";
import { IDynamicLinkParameters, IAPPType } from "@aspen/model/index";
/**
 * 生成动态链接
 * @param {IDynamicLinkParameters}
 * 参考： https://rnfirebase.io/reference/dynamic-links#buildLink、https://firebase.google.com/docs/dynamic-links/create-manually#ddl_parameters
 * 关于Android amv参数，可以打开链接的应用程序的最低版本的versionCode。如果安装的应用程序是旧版本，则用户将被带到Play商店升级应用程序。
 * 关于iOS imv参数：可以打开动态链接的最低版本，在app打开动态链接时会传递给app, 需要自己决定收到这个参数的处理动作。
 * 关于amv,imv, 小工具生成的动态链接里携带参数，注意检查准确。新闻相关的分享里不检查，避免配置错误导致用户流失。
 * 注意！！：参数变化对其他地方的影响，比如：分享、小工具、动态链接、app内部跳转等
 */
const buildDynamicLink = (params: IDynamicLinkParameters): string => {
  const {
    webPath,
    routeName,
    routeParams,
    env,
    minimumVersionCustomer,
    googleAnalyticsParameters
  } = params;
  let envConfig = getEnvConfig(env);
  const { webDomain, domainUriPrefix, fallbackUrl, packageName } = envConfig;
  let fullPath = `?routeName=${routeName}?routeParams=${routeParams}`;
  if (webPath) {
    fullPath = `${webPath}?routeName=${routeName}?routeParams=${routeParams}`;
  } else {
    // 小工具生成的页面展示时不需要确定当前路由，只需指定相应环境的主域名作为动态链接的link
    fullPath = `${webDomain}?routeName=${routeName}?routeParams=${routeParams}`;
  }
  const _domainUriPrefix = domainUriPrefix;
  const _fallbackUrl: IAPPType = fallbackUrl;
  const _packageName: IAPPType = packageName;
  const _amv = minimumVersionCustomer?.android ? minimumVersionCustomer?.android : "";
  const _imv = minimumVersionCustomer?.ios ? minimumVersionCustomer?.ios : "";
  let _dynamicLink = "";
  // 动态链接的参数拼接顺序应该不影响跳转，但是不同平台不同APP或者浏览器的兼容性可能有所不同，不要随意调整/修改
  if (_amv && _imv) {
    _dynamicLink = `${_domainUriPrefix}?&afl=${_fallbackUrl.android}&amv=${_amv}&apn=${
      _packageName.android
    }&ibi=${_packageName.ios}&ifl=${
      _fallbackUrl.ios
    }&imv=${_imv}&isi=${APP_STORE_ID}&link=${encodeURIComponent(fullPath)}`;
    console.info("_dynamicLink", _dynamicLink);
  } else {
    _dynamicLink = `${_domainUriPrefix}?&afl=${_fallbackUrl.android}&apn=${
      _packageName.android
    }&ibi=${_packageName.ios}&ifl=${_fallbackUrl.ios}&isi=${APP_STORE_ID}&link=${encodeURIComponent(
      fullPath
    )}`;
    console.info("_dynamicLink", _dynamicLink);
  }
  if (googleAnalyticsParameters) {
    _dynamicLink = `${_dynamicLink}&${googleAnalyticsParameters}`;
  }
  return _dynamicLink;
};

export { buildDynamicLink };
