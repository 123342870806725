import type { CSSProperties } from "react";
import React, { useCallback, useState } from "react";
import styles from "./index.module.less"
import { Button } from "antd";

interface IProps {
  onChange: (num: number) => void;
  style?: CSSProperties
}

export const Counter = (props: IProps) => {
  const {onChange, style} = props;
  const [num, setNum] = useState<number>(1)
  const subDisable = num <= 1;

  const _add = useCallback(() => {
    setNum(num + 1)
    onChange && onChange(num + 1);
  }, [num]);

  const _sub = useCallback(() => {
    if (subDisable) {
      return
    }
    let _count = num - 1;
    if (_count <= 1) {
      _count = 1;
    }
    setNum(_count)
    onChange && onChange(_count);
  }, [num]);
  return (
    <div className={styles.counter} style={style}>
      <Button disabled={subDisable} className={styles.actionBtn} onClick={_sub}>-</Button>
      <span style={{fontSize: 14}}>{num}</span>
      <Button className={styles.actionBtn} onClick={_add}>+</Button>
    </div>
  );
}
