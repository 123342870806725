import React from "react";
import { Slider } from "antd";

interface IProps {
    defaultValue: [number, number];
    onAfterChange: (value: [number, number]) => void;
}

const VentureSlider = (props: IProps) => {
    const { defaultValue, onAfterChange } = props;

    return (
        <Slider
            range
            defaultValue={defaultValue}
            min={0}
            max={200}
            step={10}
            tooltip={{
                open: true,
                formatter: value => (
                    <div style={{ fontSize: 12 }}>
                        {Number(value)}%
                    </div>
                ),
                placement: "bottom"
            }}
            onAfterChange={onAfterChange}
        />
    )
};

export default React.memo(VentureSlider);
