import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styles from "@aspen/theme/Event.module.less";
import { CountDownTimer, formatCountTime } from "@aspen/libs";

interface IProps {
  startTime: number;
  endTime: number;
  countStatusEnd?: () => void;
  innerViewSize?: number;
  size?: "large" | "normal";
}

const CountdownView = (props: IProps) => {
  const {startTime, endTime, countStatusEnd, innerViewSize = 40, size = "normal"} = props;
  const timer = new CountDownTimer(startTime, endTime);
  const [remain, setRemain] = useState<number>(0)
  const {d, h, m, s} = useMemo(()=>{
    return formatCountTime(remain);
  },[remain,endTime])
  useEffect(() => {
    handleTimer()
  }, [])
  const handleTimer = useCallback(() => {
    timer?.setCallBack((offset: number) => {
      const _remain = endTime - startTime - offset * 1000;
      setRemain(_remain);
      if (_remain < 1000) {
        timer?.stop();
        setTimeout(() => {
          countStatusEnd && countStatusEnd();
        }, 1000);
      }
    });
    timer?.start();
  }, []);

  const handleSize = useCallback((size?: "large" | "normal") => {
    let sizeNum:number | undefined = undefined
    if (size) {
      switch (size) {
        case "large":
          sizeNum = 100;
          break;
        case "normal":
          sizeNum = 40;
          break;
      }
    } else if (innerViewSize) {
      sizeNum = innerViewSize
    }
    return sizeNum
  },[size]);

  const renderSplit = useCallback(() => {
    const splitSize = handleSize(size)! / 20
    return (
      <div style={{
        width: 6,
        marginLeft: handleSize(size)! / 10,
        marginRight: handleSize(size)! / 10,
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center"
      }}>
        <div className={styles.point} style={{width: splitSize, height: splitSize}}/>
        <div className={styles.point} style={{marginTop: 8, width: splitSize, height: splitSize}}/>
      </div>
    );
  },[size])

  const timerItemSize = useMemo(()=>{
    return handleSize(size)
  },[size])
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      {Number(d) > 0 ? (
        <TimerItem style={{marginRight: size === "large" ? 20 : 12}} text={d + "d"} size={timerItemSize}/>
      ) : undefined}
      <TimerItem text={h + ""} size={timerItemSize}/>
      {renderSplit()}
      <TimerItem text={m + ""} size={timerItemSize}/>
      {renderSplit()}
      <TimerItem
        size={timerItemSize}
        text={s + ""}
        style={{
          color: remain < 60 * 1000 && remain > 0 ? "#f00" : "#fff",
        }}
      />
    </div>
  );
};

const TimerItem = memo((props: { text: string; size?: number; style?: React.CSSProperties; }) => {
  const {text, style, size = 40} = props;
  return (
    <div className={styles.timerItem} style={{width: size, height: size, ...style}}>
      <span style={{fontSize: size / 2}}>{text}</span>
    </div>
  );
});
export { CountdownView, TimerItem }
