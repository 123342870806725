"use client";
import React, { useEffect, useState } from "react";
import { getCustomerData } from "@aspen/services";
import { useRouter } from "next/router";
import ModalRiskReminder from "../profile/modalRiskReminder";
import {
  PROFILE_PATHS,
  USER_AUTH,
  USER_COUNTRY_CODE,
  USER_NATIONAL_NUMBER,
  USER_ROLE_POWER
} from "@aspen/libs";

interface IProps {
  [key: string]: any; // 保留此any, HOC组件，props可能为任意类型
}

export const WithRiskReminder = (WrappedComponent) => {
  const RiskReminder: React.FC<IProps> = (props: IProps) => {
    const router = useRouter();
    const [visible, setVisible] = useState(false);

    const fetchCustomerInfo = () => {
      getCustomerData().then((res) => {
        if (res?.code == "0") {
          if (!res?.data?.isGoogleCertified && !res?.data?.nationalNumber) {
            setVisible(true);
            return;
          }
          if (res?.data?.countryCode && res?.data?.nationalNumber) {
            localStorage.setItem(USER_COUNTRY_CODE, res?.data?.countryCode);
            localStorage.setItem(USER_NATIONAL_NUMBER, res?.data?.nationalNumber);
          }
        }
      });
    };

    useEffect(() => {
      // 代客操作时 目前只有子账号 不判断
      const user_auth = typeof window != "undefined" && localStorage.getItem(USER_AUTH);
      // @ts-ignore
      const actionForOthers = USER_ROLE_POWER[user_auth]?.actionForOthers;
      if (!actionForOthers) {
        fetchCustomerInfo();
      }
    }, []);

    const _handleHideRiskReminder = () => {
      setVisible(false);
      router.back();
    };

    const _handleChangeVerifyMethod = (key) => {
      setVisible(false);
      router.push({
        pathname: PROFILE_PATHS.PROFILE,
        query: {
          verify: key
        }
      });
    };

    return (
      <>
        <WrappedComponent {...props} />
        {visible && (
          <ModalRiskReminder
            visible={visible}
            viewLater={false}
            handleCancel={_handleHideRiskReminder}
            handleChangeVerifyMethod={_handleChangeVerifyMethod}
          />
        )}
      </>
    );
  };
  return RiskReminder;
};
